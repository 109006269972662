<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Survey Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey Title</label
                    >
                    <v-text-field
                      disabled
                      outlined
                      placeholder="Survey Title"
                      v-model="survey.title"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey Description</label
                    >
                    <v-text-field
                      disabled
                      outlined
                      placeholder="Survey Description"
                      v-model="survey.description"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Question Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Question Title</label
                    >
                    <v-text-field
                      :disabled="isSurveyDisabled"
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Question Title"
                      v-model="question.title"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.question.title.$errors)
                      "
                      @blur="v$.question.title.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Question Status</label
                    >
                    <v-select
                      :disabled="isSurveyDisabled"
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Question Status"
                      :items="questionStatuses"
                      item-text="value"
                      item-value="key"
                      v-model="question.status"
                      :error-messages="
                        getFieldValidationErrors(v$.question.status.$errors)
                      "
                      @blur="v$.question.status.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Question Type</label
                    >
                    <v-select
                      :disabled="isSurveyDisabled"
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Question Type"
                      :items="questionTypes"
                      item-text="value"
                      item-value="key"
                      v-model="question.type"
                      :error-messages="
                        getFieldValidationErrors(v$.question.type.$errors)
                      "
                      @blur="v$.question.type.$touch"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row v-if="showAnswerOptions">
                  <v-col cols="12" md="12" class="py-0">
                    <h4>Answer Options</h4>
                  </v-col>
                </v-row>

                <v-row v-if="showAnswerOptions">
                  <v-col cols="11" class="py-0">
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Answer text..."
                      v-model="answerOptionText"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="1" class="py-0">
                    <v-btn
                      @click="addQuestionAnswerOption"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                      color="#5e72e4"
                      >Add</v-btn
                    >
                  </v-col>
                </v-row>

                <v-row
                  v-if="showAnswerOptions && questionAnswerOptions.length === 0"
                >
                  <v-col cols="11" class="py-0" style="text-align: center">
                    Please add an answer option.
                  </v-col>
                </v-row>

                <v-row class="mb-2" v-if="showAnswerOptions">
                  <v-col cols="12" class="py-0">
                    <v-card
                      v-for="(
                        questionAnswerOption, key
                      ) in questionAnswerOptions"
                      :key="key"
                      elevation="1"
                      style="margin-top: 20px !important"
                    >
                      <v-row>
                        <v-col cols="1" class="pl-10">
                          <v-icon>ni ni-bulb-61</v-icon>
                        </v-col>
                        <v-col cols="10">{{ questionAnswerOption }}</v-col>
                        <v-col cols="1">
                          <v-icon
                            color="red"
                            size="30"
                            style="cursor: pointer"
                            @click="
                              removeQuestionAnswerOption(questionAnswerOption)
                            "
                          >
                            ni ni-fat-remove
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>

                <v-btn
                  :disabled="isSurveyDisabled"
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="submitButton.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  style="margin-top: 30px"
                  color="#5e72e4"
                  >Submit</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "survey-questions-create",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  validations() {
    return {
      question: {
        title: { required },
        status: { required },
        type: { required },
      },
    };
  },
  props: {
    surveyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      answerOptionText: "",
      question: {
        title: "",
        status: "",
        type: "",
      },
      questionAnswerOptions: [],
      questionTypes: [
        {
          key: "text",
          value: "Free Text",
        },
        {
          key: "one_selection",
          value: "One Selection",
        },
        {
          key: "multiple_selections",
          value: "Multiple Selections",
        },
      ],
      survey: {
        id: 0,
        title: "",
        status: "",
        description: "",
        numQuestions: "",
      },
      submitButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
    isSurveyDisabled() {
      return !["draft", "active"].includes(this.survey.status);
    },

    showAnswerOptions() {
      return ["one_selection", "multiple_selections"].includes(
        this.question.type
      );
    },
  },
  methods: {
    addQuestionAnswerOption() {
      if (!this.answerOptionText || this.answerOptionText.trim() === "") {
        this.$notify.warning("Please add answer text first.");
        return;
      }

      this.questionAnswerOptions.push(this.answerOptionText.trim());

      this.answerOptionText = "";
    },

    removeQuestionAnswerOption(option) {
      this.questionAnswerOptions = this.questionAnswerOptions.filter(
        (e) => e !== option
      );
    },

    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const questionBody = {
        question: this.question.title,
        status: this.question.status,
        type: this.question.type,
        options: this.questionAnswerOptions,
      };

      this.$http
        .post(
          `/surveys/${this.surveyId}/questions`,
          questionBody,
          this.sessionStore.getHttpConfig
        )
        .then(async () => {
          this.$notify.success("Question created successfully!");
          this.$router.push({
            name: "Edit Survey",
            params: {
              id: this.surveyId,
            },
          });
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while creating the survey question. Please try again later or contact support."
          );
          console.error("Error while creating survey question: ", error);
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },
  },
  mounted() {
    this.$http
      .get(`/surveys/${this.surveyId}`, this.sessionStore.getHttpConfig)
      .then((result) => {
        const survey = result.data.data[0];

        this.survey.id = survey.id;
        this.survey.status = survey.status;
        this.survey.title = survey.title;
        this.survey.description = survey.description;
        this.survey.numQuestions = survey.survey_questions.length;
      })
      .catch((error) => {
        this.$notify.error(
          "An error occurred while loading the survey question. Please try again later or contact support."
        );
        console.error("Error while fetching survey question: ", error);
      });
  },
};
</script>
